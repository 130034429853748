<template>
    <v-container>
        <div
            style="display: flex; flex-flow: column wrap"
        >
            <div>
                <v-card>
                    <v-card-title class="justify-center">
                        <span class="text-h5 text-center"> {{ $t('awards.winner.title') }}</span>
                    </v-card-title>

                    <v-card-text
                        class="mt-0 pt-0"
                        style="display: flex; align-items: center; flex-flow: column wrap"
                    >
                        <span
                            class="text-h5 winner-simple-text"
                            style="color: #006ff5;"
                        >{{ `${getUser.name}` }}</span>
                        <span
                            class="text-h6 winner-simple-text"
                        >{{ $t('awards.nominated.subtitle') }}
                        </span>

                        <img
                            class=" my-5"
                            src="@/assets/img/winners/nominated.jpg"
                        >

                        <!--                        <span
                            class="text-h7 mt-3 winner-simple-text"
                        >
                            {{ $t('awards.nominated.description') }}
                        </span>-->
                    </v-card-text>

                    <router-view />

                    <nextPostBtn :url-next-post="nextPost" />
                </v-card>
            </div>
        </div>
    </v-container>
</template>

<script>
/* import axios from '@/plugins/axios' */
import { mapGetters } from 'vuex'
import postMixin from "@/mixins/postMixin";
import nextPostBtn from '@/components/posts/nextPostBtn'

export default {
    name: 'Nominated',
    components: { nextPostBtn },
    mixins: [postMixin],
    data: function () {
        return {

        }
    },
    computed: {
        ...mapGetters('configGeneral', ['wasNominated']),
        ...mapGetters(['getUser'])
    },
    async created() {

    },
    mounted() {

    },
    methods: {

    }
}
</script>

<style scoped>

@media (min-width: 600px) {

  .areas{
    margin-left: 4rem;
  }
}
</style>
